<template>
    <div>
        <div class="explore">
            <div class="text-h5 font-weight-bold mb-10">{{ $t('ExploreNews.Title') }}</div>
            <div v-if="newsil8n == 'en'">
                <v-card elevation="0" v-for="(item, index) in newsList" :key="index" :href="item.urlEn" target="_blank"
                    class="d-none d-sm-flex pa-6 mb-6">
                    <div v-if="item.imageEn">
                        <img contain width="200" height="140" :src="'https://image.supercells.pro/images/' + item.imageEn"
                            class="rounded">
                    </div>
                    <div class="mx-6 d-flex flex-column justify-space-between pb-1" style="width:calc(100% - 224px)">
                        <div>
                            <div class="text-h6 text-truncate">{{ item.titleEn }}</div>
                            <div class="text-clamp grey--text mt-4">{{ item.keywordsEn }}</div>
                        </div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.sourceEn }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>

                    </div>
                </v-card>

                <v-card elevation="0" v-for="(item, index) in newsList" :key="'mobile' + index" :href="item.urlEn"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <v-img v-if="item.imageEn" contain width="100%"
                        :src="'https://image.supercells.pro/images/' + item.imageEn" class="rounded"></v-img>
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.titleEn }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.keywordsEn }}</div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.sourceEn }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>
                    </div>
                </v-card>
            </div>

            <div v-if="newsil8n == 'ja'">
                <v-card elevation="0" v-for="(item, index) in newsList" :key="index" :href="item.urlJap" target="_blank"
                    class="d-none d-sm-flex pa-6 mb-6">
                    <div v-if="item.imageJap">
                        <img contain width="200" height="140" :src="'https://image.supercells.pro/images/' + item.imageJap"
                            class="rounded">
                    </div>
                    <div class="mx-6 d-flex flex-column justify-space-between pb-1" style="width:calc(100% - 224px)">
                        <div>
                            <div class="text-h6 text-truncate">{{ item.titleJap }}</div>
                            <div class="text-clamp grey--text mt-4">{{ item.keywordsJap }}</div>
                        </div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.sourceJap }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" v-for="(item, index) in newsList" :key="'mobile' + index" :href="item.urlJap"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <v-img v-if="item.imageJap" contain width="100%"
                        :src="'https://image.supercells.pro/images/' + item.imageJap" class="rounded"></v-img>
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.titleJap }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.keywordsJap }}</div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.sourceJap }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>
                    </div>
                </v-card>
            </div>

            <div v-if="newsil8n == 'zh'">
                <v-card elevation="0" v-for="(item, index) in newsList" :key="index" :href="item.url" target="_blank"
                    class="d-none d-sm-flex justify-space-between pa-6 mb-6">
                    <div v-if="item.image">
                        <img contain width="200" height="140" :src="'https://image.supercells.pro/images/' + item.image"
                            class="rounded">
                    </div>
                    <div class="mx-6 d-flex flex-column justify-space-between pb-1" style="width:calc(100% - 224px)">
                        <div>
                            <div class="text-h6 text-truncate">{{ item.title }}</div>
                            <div class="text-clamp grey--text mt-4">{{ item.keywords }}</div>
                        </div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.source }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" v-for="(item, index) in newsList" :key="'mobile' + index" :href="item.url"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <v-img v-if="item.image" contain width="100%" :src="'https://image.supercells.pro/images/' + item.image"
                        class="rounded"></v-img>
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.title }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.keywords }}</div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.source }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>
                    </div>
                </v-card>
            </div>

            <div v-if="newsil8n == 'ko'">
                <v-card elevation="0" v-for="(item, index) in newsList" :key="index" :href="item.urlKor" target="_blank"
                    class="d-none d-sm-flex justify-space-between pa-6 mb-6">
                    <div v-if="item.imageKor">
                        <img contain width="200" height="140" :src="'https://image.supercells.pro/images/' + item.imageKor"
                            class="rounded">
                    </div>
                    <div class="mx-6 d-flex flex-column justify-space-between pb-1" style="width:calc(100% - 224px)">
                        <div>
                            <div class="text-h6 text-truncate">{{ item.titleKor }}</div>
                            <div class="text-clamp grey--text mt-4">{{ item.keywordsKor }}</div>
                        </div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.sourceKor }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" v-for="(item, index) in newsList" :key="'mobile' + index" :href="item.urlKor"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <v-img v-if="item.imageKor" contain width="100%"
                        :src="'https://image.supercells.pro/images/' + item.imageKor" class="rounded"></v-img>
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.titleKor }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.keywordsKor }}</div>
                        <div class="d-flex">
                            <div class="grey--text text-body-2 mt-2">{{ item.sourceKor }}</div>
                            <div class="grey--text text-body-2 mt-2 mx-2">·</div>
                            <div class="grey--text text-body-2 mt-2">{{ item.createdAt | sliceStr }}</div>
                        </div>
                    </div>
                </v-card>
            </div>

            <v-pagination class="mt-10" color="#AC8250" v-model="params.current" :total-visible="10" :length="pageLength"
                @input="getlist" @next="nextPage" @prev="prevPage"></v-pagination>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../../components/Footer.vue'
import { get } from "../../request/http.js";


export default {
    components: {
        Footer
    },
    data: () => ({
        newsil8n: 'ja',
        params: {
            current: 1,
            pageSize: 20
        },
        pageNum: 0,
        pageLength: 0,
        newsList: null,
    }),
    methods: {
        // 分页下一页
        nextPage: function () {
            this.params.current + 1
        },
        // 分页上一页
        prevPage: function () {
            this.params.current - 1
        },
        getlist: async function () {
            await get(`https://capi.supercells.pro//app/api/news/pc/list?query={"current": ${this.params.current}, "pageSize": ${this.params.pageSize}}`, this.params).then(res => {
                if (res.status == 200) {
                    console.log(res);
                    this.pageNum = res.data.data.current;
                    this.pageLength = Math.ceil(res.data.data.total / this.params.pageSize);
                    this.newsList = res.data.data.data;
                }
            })
        }
    },
    mounted() {
        this.newsil8n = this.$i18n.locale
        this.getlist()
    },
    watch: {
        '$i18n.locale'(newil8n) {
            this.newsil8n = newil8n;
        },
    },
    filters: {
        sliceStr(str) {
            return str.slice(0, 10)
        }
    }
}
</script>

<style>
.explore {
    min-height: calc(100vh - 289px);
    padding: 80px 120px;
    background: #FCF9F6;
}

.text-clamp {
    word-break: break-all !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

@media screen and (max-width: 960px) {
    .explore {
        padding: 30px 24px;
    }
}
</style>